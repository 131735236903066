
import { mdiArrowLeft } from '@mdi/js'

export default {
  name: 'Login',
  data () {
    return {
      icons: {
        mdiArrowLeft
      }
    }
  }
}
